// Signin.js
// Author - Ajay Kumar
// Creation date - 27-01-2023
// gameberrylabs.com

import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles, Container, Grid } from "@material-ui/core";
import { Component } from "react";
import { PropTypes } from "prop-types";
import { Redirect } from "react-router-dom";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import axios from "axios";

const useStyles = (theme) => ({
	root: {
		display: "flex",
	},
	cover: {
		backgroundColor: "#f7f7f7d6",
		display: "flex",
		alignItems: "center",
		minWidth: "70%",
		justifyContent: "space-around",
		padding: theme.spacing(2),
	},
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	icon: {
		width: 607,
		height: 138,
	},
	info: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	container: {
		paddingTop: theme.spacing(4),
		display: "flex",
		flexDirection: "column",
		height: "100%",
		justifyContent: "center",
		paddingBottom: theme.spacing(4),
	},
	loginContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
	},
	mainBg: {
		display: "flex",
		position: "absolute",
		flexDirection: "row",
		backgroundImage: `url(login_cover.jpg)`,
		height: "100%",
		zIndex: -1,
		width: "100%",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
	},
});

class Signin extends Component {
	componentDidMount() {
		axios
			.get("/oAuthID")
			.then((resp) => resp.data)
			.then((data) => {
				if (data !== undefined && data.oAuthID !== "") {
					window.google.accounts.id.initialize({
						client_id: data.oAuthID,
						callback: this.handleLogin,
						auto_select: false,
					});
					window.google.accounts.id.renderButton(
						document.getElementById("btnGoogleSignIn"),
						{ theme: "outline", size: "large" }, // customization attributes
					);
				}
			});
	}

	handleLogin = (response) => {
		axios
			.post("/oauth", { token: response.credential })
			.then(() => {
				console.log(`post: /oauth: success`);
				this.props.history.push("/dashboard");
				this.props.setIsAuthed(true);
			})
			.catch((err) => {
				console.error(`post: /oauth: ${err}`);
			});
	};

	render() {
		const { classes } = this.props;
		return this.props.isAuthed ? (
			<Redirect exact from="/" to="dashboard" />
		) : (
			<div className={classes.root}>
				<CssBaseline />
				<main className={classes.content} color="background">
					<div className={classes.mainBg} />

					<Container maxWidth="lg" className={classes.container}>
						<Grid className={classes.loginContainer} container spacing={6}>
							<Card elevation={10} className={classes.cover}>
								<div className={classes.info}>
									<img className={classes.icon} alt="" src="icon.png"></img>
								</div>
								<div id="btnGoogleSignIn"></div>
							</Card>
						</Grid>
					</Container>
				</main>
			</div>
		);
	}
}

Signin.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(Signin);
