import React, { useEffect } from "react";
import { withStyles, Container, Grid, Paper, Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	fixedHeight: {
		minHeight: 240,
	},
	paperCol: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	updateButton: {
		backgroundColor: "white",
		marginTop: 10,
		marginBottom: 10,
		marginRight: 10,
	},
	startPaper: {
		padding: theme.spacing(2),
		display: "flex",
		backgroundColor: "#546bd6",
		justifyContent: "center",
	},
}));

const CssTextField = withStyles({
	root: {
		"& label.Mui-focused": {
			color: "white",
		},
		"& .MuiFormLabel-root": {
			color: "white",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "white",
		},
		"& .MuiOutlinedInput-input": {
			color: "white",
			fontWeight: 800,
		},

		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "white",
			},
			"&:hover fieldset": {
				borderColor: "white",
			},
			"&.Mui-focused fieldset": {
				borderColor: "white",
			},
		},
	},
})(TextField);

const SetWaitTime = ({ devServer, enqueueSnackbar }) => {
	const classes = useStyles();

	const waitTimeType = Object.freeze({
		NORMAL: "Normal",
		CHEATER: "Cheater",
	});

	const [data, setData] = React.useState({
		waitTime: 0,
		cheaterWaitTime: 0,
	});

	const getCurrentWaitTime = () => {
		axios
			.get("/api/dev/" + devServer + "/consul/waitTimeMap")
			.then((json) => json.data)
			.then((data) => {
				if (data.err) {
					throw new Error(data.err);
				}
				setData((prevData) => ({
					...prevData,
					["waitTime"]: data,
				}));
				console.log(data);
				enqueueSnackbar("Successfully fetched waitTime.", {
					variant: "success",
				});
			})
			.catch((err) => {
				enqueueSnackbar("waitTime Fetch Failed.", {
					variant: "error",
				});
				console.log(err);
			});
	};

	const getCurrentCheaterWaitTime = () => {
		axios
			.get("/api/dev/" + devServer + "/consul/cheaterLeague")
			.then((json) => json.data)
			.then((data) => {
				if (data.err) {
					throw new Error(data.err);
				}
				setData((prevData) => ({
					...prevData,
					["cheaterWaitTime"]: data,
				}));
				console.log(data);
				enqueueSnackbar("Successfully fetched cheaterWaitTime.", {
					variant: "success",
				});
			})
			.catch((err) => {
				enqueueSnackbar("cheaterWaitTime Fetch Failed.", {
					variant: "error",
				});
				console.log(err);
			});
	};

	useEffect(() => {
		getCurrentCheaterWaitTime();
		getCurrentWaitTime();
	}, [devServer]);

	const putWaitTime = () => {
		axios
			.post("/api/dev/" + devServer + "/consul/waitTimeMap", data.waitTime)
			.then((json) => json.data)
			.then((data) => {
				if (data.err) {
					throw new Error(data.err);
				}
				enqueueSnackbar("Done.", {
					variant: "success",
				});
			})
			.catch((err) => {
				enqueueSnackbar("Something went wrong. " + err, {
					variant: "error",
				});
			});
	};

	const putCheaterWaitTime = () => {
		axios
			.post(
				"/api/dev/" + devServer + "/consul/cheaterLeague",
				data.cheaterWaitTime,
			)
			.then((json) => json.data)
			.then((data) => {
				if (data.err) {
					throw new Error(data.err);
				}
				enqueueSnackbar("Done.", {
					variant: "success",
				});
			})
			.catch((err) => {
				enqueueSnackbar("Something went wrong. " + err, {
					variant: "error",
				});
			});
	};

	const handleInputChange = (event) => {
		const { id, value } = event.target;

		if (id == waitTimeType.NORMAL) {
			setData((prevData) => ({
				...prevData,
				["waitTime"]: value,
			}));
		} else if (id == waitTimeType.CHEATER) {
			setData((prevData) => ({
				...prevData,
				["cheaterWaitTime"]: value,
			}));
		}
	};

	return (
		<Container maxWidth="lg" className={classes.container}>
			<Grid container spacing={3}>
				<Grid item xs={12} md={12} lg={12}></Grid>
				<Paper className={classes.startPaper}>
					<Grid justify="space-between" container spacing={3}>
						<Grid item>
							<CssTextField
								className={classes.margin}
								label="WaitTime"
								id={waitTimeType.NORMAL}
								name="user-waitTime"
								variant="outlined"
								type="number"
								value={data.waitTime}
								onChange={handleInputChange}
							/>
						</Grid>
						<Grid item>
							<Button
								className={classes.updateButton}
								aria-haspopup="true"
								aria-controls="lock-menu"
								variant="contained"
								onClick={putWaitTime}
							>
								Update
							</Button>
						</Grid>
					</Grid>
				</Paper>
				<Grid item xs={12} md={12} lg={12}></Grid>
				<Paper className={classes.startPaper}>
					<Grid justify="space-between" container spacing={3}>
						<Grid item>
							<CssTextField
								className={classes.margin}
								label="WaitTime - cheaters"
								id={waitTimeType.CHEATER}
								name="user-waitTime-cheaters"
								variant="outlined"
								type="number"
								value={data.cheaterWaitTime}
								onChange={handleInputChange}
							/>
						</Grid>
						<Grid item>
							<Button
								className={classes.updateButton}
								aria-haspopup="true"
								aria-controls="lock-menu"
								variant="contained"
								onClick={putCheaterWaitTime}
							>
								Update
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Container>
	);
};

export default SetWaitTime;
