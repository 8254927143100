import React, { useState } from "react";
import {
	Container,
	Grid,
	Paper,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
	Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { withSnackbar } from "notistack";

const eventType = ["None", "KTM", "KTMv4", "Treasure Road"];

const useStyles = (theme) => ({
	root: {
		display: "flex",
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	formControl: {
		minWidth: 120,
		marginRight: theme.spacing(2),
	},
	button: {
		marginTop: theme.spacing(2),
	},
	indicator: {
		color: "green",
		fontWeight: "bold",
		marginTop: theme.spacing(1),
	},
});

const EventView = ({ classes, dev, enqueueSnackbar }) => {
	const [eventData, setEventData] = React.useState({
		id: "",
		type: "None",
	});

	const handleInputChange = (event) => {
		const { id, value } = event.target;

		console.log(id, value);

		if (event.target.id == "eventID") {
			if (event.target.value != "") {
				setEventData((prevEventData) => ({
					...prevEventData,
					["type"]: "None",
				}));
			}
			setEventData((prevEventData) => ({
				...prevEventData,
				["id"]: event.target.value,
			}));
		} else {
			if (event.target.value != "") {
				setEventData((prevEventData) => ({
					...prevEventData,
					["id"]: "",
				}));
			}
			setEventData((prevEventData) => ({
				...prevEventData,
				["type"]: event.target.value,
			}));
		}
	};

	const deployEvent = () => {
		axios
			.post("/api/dev/" + dev + "/deployEvent", {
				id: eventData.id,
				type: eventData.type,
			})
			.then((json) => json.data)
			.then((data) => {
				if (data.err) {
					throw new Error(data.err);
				}
				enqueueSnackbar("Done.", {
					variant: "success",
				});
			})
			.catch((err) => {
				enqueueSnackbar("Something went wrong. " + err, {
					variant: "error",
				});
			});
	};

	return (
		<Container maxWidth="lg" className={classes.container}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<Paper className={classes.paper}>
						<FormControl variant="outlined" className={classes.formControl}>
							<InputLabel id="select-id">Event Type</InputLabel>
							<Select
								labelId="select-id"
								id="eventType"
								value={eventData.type}
								onChange={handleInputChange}
								label="Event Type"
							>
								{eventType.map((optionText, index) => (
									<MenuItem key={index} value={optionText}>
										{optionText}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Paper className={classes.paper}>
						<TextField
							id="eventID"
							label="Event ID"
							variant="outlined"
							value={eventData.id}
							onChange={handleInputChange}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Button
						className={classes.button}
						variant="contained"
						color="primary"
						disabled={eventData.type === "None" && eventData.id === ""}
						onClick={deployEvent}
					>
						Deploy
					</Button>
					{eventData.type == "None" && eventData.id == "" ? (
						<Typography variant="body2" className={classes.indicator}>
							*Please select an event type or enter an event ID.
						</Typography>
					) : null}
				</Grid>
			</Grid>
		</Container>
	);
};

export default withStyles(useStyles)(withSnackbar(EventView));
