export const UserInfoList = [
	{
		sectionTitle: "User Info",
		itemList: [
			{
				label: "Name",
				name: "DisplayName",
				type: "text",
			},
			{
				label: "isPayer",
				name: "Payer",
				type: "check-box",
			},
			{
				label: "User ID",
				name: "UID",
				type: "text",
			},
			{
				label: "CreatedAt",
				name: "CreatedAt",
				type: "time",
			},
		],
	},
	{
		sectionTitle: "User Currency",
		itemList: [
			{
				label: "Free Coins",
				name: "FreeCoins",
				type: "number",
			},
			{
				label: "Free Potions",
				name: "FreePotions",
				type: "number",
			},
			{
				label: "Paid Coins",
				name: "PaidCoins",
				type: "number",
			},
			{
				label: "Paid Potions",
				name: "PaidPotions",
				type: "number",
			},
		],
	},
	{
		sectionTitle: "User Hearts",
		itemList: [
			{
				label: "Hearts Paid",
				name: "Hearts.Paid",
				type: "number",
			},
			{
				label: "Hearts Free",
				name: "Hearts.Free",
				type: "number",
			},
		],
	},
	{
		sectionTitle: "User Games",
		itemList: [
			{
				label: "GamesPlayed",
				name: "UserStats.GamesPlayed",
				type: "number",
			},
			{
				label: "Win Streak",
				name: "UserStats.WinStreak",
				type: "number",
			},
			{
				label: "Private Games Played",
				name: "UserStats.PGamesPlayed",
				type: "number",
			},
			{
				label: "2P 500 Games Played (Only Lose/Win)",
				name: "UserStats.Public2PGames500",
				type: "number",
			},
		],
	},
	{
		sectionTitle: "Season",
		itemList: [
			{
				label: "Token Count",
				name: "UserStats.SeasonTokenCount",
				type: "number",
			},
			{
				label: "Pass Enabled",
				name: "UserStats.PassEnabled",
				type: "check-box",
			},
		],
	},
	{
		sectionTitle: "E Mail",
		itemList: [
			{
				label: "Email",
				name: "Email",
				type: "text",
			},
			{
				label: "EmailRequestData",
				name: "EmailRequestData",
				type: "text",
			},
		],
	},
	{
		sectionTitle: "User XP",
		itemList: [
			{
				label: "XP Value",
				name: "XP",
				type: "number",
			},
			{
				label: "XP Expiry",
				name: "XPMultiplierExpiry",
				type: "time",
			},
			{
				label: "XP Mul",
				name: "XPMultiplier",
				type: "number",
			},
		],
	},
	{
		sectionTitle: "Collections",
		itemList: [
			{
				label: "Platinum Coins",
				name: "PlatinumCoins",
				type: "number",
			},
			{
				label: "Rainbow Ink",
				name: "RainbowInk",
				type: "number",
			},
			{
				label: "Gold Spanner",
				name: "GoldSpanner",
				type: "number",
			},
		],
	},
	{
		sectionTitle: "Cannon Event / Treasure",
		itemList: [
			{
				label: "Primary Ammo",
				name: "Event.PrimaryItem",
				type: "number",
			},
			{
				label: "Secondary Ammo",
				name: "Event.SecondaryItem",
				type: "number",
			},
			{
				label: "Progress",
				name: "Event.Progress",
				type: "number",
			},
			{
				label: "Rewards Collected",
				name: "Event.RewardCollected",
				type: "number",
			},
			{
				label: "Card Reward",
				name: "Event.CardIDGiven",
				type: "text",
			},
		],
	},
	{
		sectionTitle: "Special Parchisi / Streak",
		itemList: [
			{
				label: "Games Won",
				name: "SpecialParchisStats.GamesWon",
				type: "number",
			},
			{
				label: "Rentries",
				name: "SpecialParchisStats.ReEntries",
				type: "number",
			},
			{
				label: "FeesPaid",
				name: "SpecialParchisStats.FeesPaid",
				type: "check-box",
			},
		],
	},
	{
		sectionTitle: "Chest Data",
		itemList: [
			{
				label: "Chest Slot 1",
				name: "ChestsData[0]",
				type: "select",
				values: [0, 1, 2, 3, 4, 5, 6, 7, 8],
			},
			{
				label: "Chest Slot 2",
				name: "ChestsData[1]",
				type: "select",
				values: [0, 1, 2, 3, 4, 5, 6, 7, 8],
			},
			{
				label: "Chest Slot 3",
				name: "ChestsData[2]",
				type: "select",
				values: [0, 1, 2, 3, 4, 5, 6, 7, 8],
			},
		],
	},
	{
		sectionTitle: "Opening Chest",
		itemList: [
			{
				label: "Chest ID",
				name: "OpeningChest.i",
				type: "number",
			},
			{
				label: "ChestOpen Time",
				name: "OpeningChest.t",
				type: "time",
			},
		],
	},
	{
		sectionTitle: "Pick Card",
		itemList: [
			{
				label: "PickCardData",
				name: "PickCardsData",
				type: "text",
			},
			{
				label: "PickCard Time",
				name: "LastMysteryBoxTime",
				type: "time",
			},
		],
	},
	{
		sectionTitle: "Fortune Data",
		itemList: [
			{
				label: "Next Free Time",
				name: "FortuneData.NextFreeFortuneTime",
				type: "time",
			},
			{
				label: "Fortune Type",
				name: "FortuneData.Type",
				type: "select",
				values: [0, 1],
			},
		],
	},
	{
		sectionTitle: "Ads",
		itemList: [
			{
				label: "Ad Number",
				name: "AdNumber",
				type: "number",
			},
			{
				label: "Ad Reset Time",
				name: "AdReset",
				type: "time",
			},
		],
	},
	{
		sectionTitle: "Gold Collector",
		itemList: [
			{
				label: "Coins",
				name: "CollectorInfo.Coins",
				type: "number",
			},
			{
				label: "Coins Level, Hammer",
				name: "CollectorInfo.CoinsLevel",
				type: "number",
			},
		],
	},
	{
		sectionTitle: "VIP",
		itemList: [
			{
				label: "VIP Points",
				name: "VipData.VipPoints",
				type: "number",
			},
			{
				label: "Last Reduction Time",
				name: "VipData.LastReductionTime",
				type: "time",
			},
		],
	},
	{
		sectionTitle: "Card Collection",
		itemList: [
			{
				label: "Album No",
				name: "RandAlbumNo",
				type: "number",
			},
			{
				label: "Get a Random Card",
				name: "CardColection",
				type: "button",
			},
		],
	},
	{
		sectionTitle: "Card Collection particular card",
		itemList: [
			{
				label: "Album No",
				name: "AlbumNo",
				type: "number",
			},
			{
				label: "Collection No",
				name: "CollectionNo",
				type: "number",
			},
			{
				label: "Card No",
				name: "CardNo",
				type: "number",
			},
			{
				label: "Get Card",
				name: "CardColection",
				type: "buttonNew",
			},
		],
	},
	{
		sectionTitle: "League",
		itemList: [
			{
				label: "League Tier",
				name: "CurrentLeague",
				type: "number",
			},
			{
				label: "Add bots to user current league",
				name: "League Bots",
				type: "LeagueBotsBtn",
			},
		],
	},
	{
		sectionTitle: "Ludo VIP",
		itemList: [
			{
				label: "Maximum VIP Type",
				name: "VipData.MaxType",
				type: "select",
				values: [0, 1, 2],
			},
			{
				label: "Current VIP Type",
				name: "VipData.Type",
				type: "select",
				values: [0, 1, 2],
			},
			{
				label: "Expiry",
				name: "VipData.Expiry",
				type: "time",
			},
			,
			{
				label: "DailyReward collected time",
				name: "VipData.RewardCollectTime",
				type: "time",
			},
		],
	},
];
