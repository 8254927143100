import React, { useEffect, useState } from "react";
import Dashboard from "./views/Dashboard";
import Signin from "./components/Signin";
import { HashRouter, Route, Redirect } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "notistack"; // Import SnackbarProvider and useSnackbar
import axios from "axios";

export default function App() {
	const [isAuthed, setIsAuthed] = useState(false);

	useEffect(() => {
		axios
			.get("/oauth")
			.then(() => {
				console.log(`user is authed`);
				setIsAuthed(true);
			})
			.catch((err) => {
				setIsAuthed(false);
				console.error(`error in getting /oauth, error: ${err}`);
				// Handle error, maybe show a snackbar here
			});
	}, []);

	return (
		<SnackbarProvider // Wrap your App component with SnackbarProvider
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			maxSnack={3}
		>
			<HashRouter>
				<div>
					<Route
						path="/"
						render={(props) => (
							<Signin
								{...props}
								isAuthed={isAuthed}
								setIsAuthed={setIsAuthed}
							/>
						)}
					/>
					<PrivateRoute
						path="/dashboard"
						component={Dashboard}
						isAuthed={isAuthed}
						setIsAuthed={setIsAuthed}
					/>
				</div>
			</HashRouter>
		</SnackbarProvider>
	);
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({
	component: Component,
	isAuthed,
	setIsAuthed,
	...rest
}) {
	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthed ? (
					<Component {...props} setIsAuthed={setIsAuthed} />
				) : (
					<Redirect exact from="/dashboard" to="/" />
				)
			}
		/>
	);
}
