import React from "react";
import clsx from "clsx";
import {
	withStyles,
	Container,
	Grid,
	Paper,
	Button,
	CircularProgress,
	Divider,
} from "@material-ui/core";
import { Component } from "react";
import { PropTypes } from "prop-types";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Refresh from "@material-ui/icons/Refresh";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withSnackbar } from "notistack";
import Commit from "../components/Commit";

const drawerWidth = 240;

const useStyles = (theme) => ({
	root: {
		display: "flex",
	},
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paperCol: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	fixedHeight: {
		minHeight: 240,
	},
	textField: {
		width: 150,
	},
	submitButton: {
		width: 150,
	},
	iconButton: {
		marginLeft: 40,
	},
	startPaper: {
		padding: theme.spacing(2),
		display: "flex",
		backgroundColor: "#546bd6",
		justifyContent: "center",
	},
	activeBranches: {
		padding: theme.spacing(2),
		display: "flex",
		justifyContent: "center",
	},
	activeBranch: {
		padding: theme.spacing(1),
		display: "flex",
		justifyContent: "left",
	},
	playIcon: {
		color: "white",
	},
	updateButton: {
		backgroundColor: "white",
		marginTop: 10,
		marginBottom: 10,
		marginRight: 10,
	},
	deleteButton: {
		backgroundColor: "#ec1010",
		color: "white",
		marginTop: 10,
		marginBottom: 10,
		marginRight: 10,
	},
	autocomplete: {
		display: "inline-flex",
	},
	inputBar: {
		display: "flex",
		width: "100%",
		justifyContent: "space-between",
		alignItems: "center",
	},
	loading: {
		display: "flex",
		width: "100%",
		justifyContent: "center",
		marginTop: theme.spacing(4),
	},
	activeLoading: {
		display: "flex",
		width: "100%",
		justifyContent: "center",
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	branchLoader: {
		marginLeft: theme.spacing(3),
	},
});

const CssTextField = withStyles({
	root: {
		"& label.Mui-focused": {
			color: "white",
		},
		"& .MuiFormLabel-root": {
			color: "white",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "white",
		},
		"& .MuiOutlinedInput-input": {
			color: "white",
			fontWeight: 800,
		},

		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "white",
			},
			"&:hover fieldset": {
				borderColor: "white",
			},
			"&.Mui-focused fieldset": {
				borderColor: "white",
			},
		},
	},
})(TextField);

class Deploy extends Component {
	getCommits = () => {
		const { classes } = this.props;
		if (this.props.loading) {
			return (
				<div className={classes.loading}>
					<CircularProgress />
				</div>
			);
		}
		return this.props.commits.map((commit) => (
			<Commit
				deployLoading={this.props.deployLoading}
				commit={commit}
				deployBranch={this.props.deployBranch}
			></Commit>
		));
	};

	render() {
		const { classes } = this.props;
		const fixedHeightPaper = clsx(classes.paperCol, classes.fixedHeight);
		return (
			<Container maxWidth="lg" className={classes.container}>
				<Grid container spacing={3}>
					<Grid item xs={8} md={8} lg={8}>
						<Paper className={classes.startPaper}>
							<Grid justify="space-between" container spacing={3}>
								<Grid item className={classes.inputBar}>
									{this.props.loadingBranch ? (
										<CircularProgress
											className={classes.branchLoader}
											color="secondary"
										/>
									) : (
										<Autocomplete
											className={classes.autocomplete}
											id="combo-box-demo"
											onChange={this.props.selectBranch}
											onInputChange={this.props.changeSelectBranchInput}
											value={this.props.selectedBranch}
											inputValue={this.props.selectedBranchInput}
											options={this.props.branches}
											getOptionLabel={(option) => option}
											style={{ width: 500 }}
											renderInput={(params) => (
												<CssTextField
													{...params}
													label="Banch Name"
													variant="outlined"
												/>
											)}
										/>
									)}
									<IconButton
										disabled={this.props.loadingBranch}
										edge="start"
										color="inherit"
										aria-label="open drawer"
										className={classes.iconButton}
										onClick={() => {
											this.props.fetchCommits(this.props.selectedBranch);
										}}
									>
										<Refresh fontSize="large" className={classes.playIcon} />
									</IconButton>
								</Grid>
							</Grid>
						</Paper>
						{this.getCommits()}
					</Grid>
					<Grid item xs={4} md={4} lg={4}>
						<Paper className={classes.activeBranches}>
							<Grid justify="space-between" container spacing={3}>
								{this.props.acitveLoading ? (
									<div className={classes.activeLoading}>
										<CircularProgress />
									</div>
								) : (
									this.props.activeBranches.map((branch, id) => (
										<Grid
											key={id}
											className={classes.activeBranch}
											xs={12}
											md={12}
											lg={12}
										>
											<b>{this.props.devs[id]}</b> - {branch}
										</Grid>
									))
								)}
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		);
	}
}

Deploy.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(withSnackbar(Deploy));
